// Cutoms Gray colors for theme
$gray-darker: #232735;
$gray-dark: #3a3f51;
$gray: #dde6e9;
$gray-light: #e4eaec;
$gray-lighter: #edf1f2;
$gray-darker: #b0adde;

// Custom brand colors for theme
$inverse: #42cb33;
$purple: #a567ff;
$pink: #e91e63;
$yellow: #ffeb3b;
$green: #009688;
$white: #ffffff;

$primary: #1e9646;
$secondary: #423f52;
$success: #4caf50;
$info: #2196f3;
$warning: #ff9800;
$danger: #f44336;
$dark: #607d8b;

// Custom color variants
$amountOfLight: 10%;

$primary-light: lighten($primary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$secondary-light: lighten($secondary, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height: 65px;

// Button secondary redefinition style for theme
$btn-secondary-color: #333;
$btn-secondary-bg: #fff;
$btn-secondary-border: #eaeaea;

// Top Border color for custom default cards (.card-default)
$card-default-border-color: #cfdbe2;

// ---------------------
// Utilities variables
// ---------------------

$border-color: rgba(0, 0, 0, 0.12);

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
  'purple': $purple,
  'pink': $pink,
  'yellow': $yellow,
  'green': $green,
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'inverse': $inverse,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark,
  'success-light': $success-light,
  'success-dark': $success-dark,
  'info-light': $info-light,
  'info-dark': $info-dark,
  'warning-light': $warning-light,
  'warning-dark': $warning-dark,
  'danger-light': $danger-light,
  'danger-dark': $danger-dark,
  'green-light': $green-light,
  'green-dark': $green-dark,
  'pink-light': $pink-light,
  'pink-dark': $pink-dark,
  'purple-light': $purple-light,
  'purple-dark': $purple-dark,
  'inverse-light': $inverse-light,
  'inverse-dark': $inverse-dark,
  'yellow-light': $yellow-light,
  'yellow-dark': $yellow-dark,
);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);

// Body

$body-bg: #ffffff;
$body-color: rgba(0, 0, 0, 0.8);

$link-color: #106cc8;

$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

$line-height-base: 1.528571429;

$headings-line-height: 1.1;

$text-muted: #909fa7;

// Tables

$table-accent-bg: #fafbfc;
$table-hover-bg: #fafbfc;

$table-border-color: #eee;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

$input-height: 2.1875rem; // 35px

// Forms

$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color: #66afe9;
$input-placeholder-color: #7ed04a;

$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

// Dropdowns

$dropdown-border-color: #e1e1e1;

// Navs

$nav-tabs-link-active-bg: #fff;

// Jumbotron

$jumbotron-bg: #fcfcfc;

// List group

$list-group-border-color: #e4eaec;

// Image thumbnails

$thumbnail-padding: 0.125rem;

// Pagination

$pagination-color: $body-color;

// Breadcrumbs

$breadcrumb-bg: #fafafa;
$breadcrumb-divider-color: #c1c2c3;
$breadcrumb-active-color: $body-color;

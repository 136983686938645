/* ========================================================================
     Component: layout
 ========================================================================== */

//
// Main layout
// This file controls the presentation of all components
//  .wrapper
//     .section-container
//       .content-wrapper
//     .aside-container
//
// If modified or removed make sure to check the variable
// shared with other components
// -------------------------------------------------------------
$aside-wd: 200px;
$aside-wd-collapsed: 70px;
$aside-wd-collapsed-text: 90px;
$aside-bg: #fff;

$content-bg: $body-bg;
$content-heading-bg: #fafbfc;
$content-heading-border: #cfdbe2;
$content-padding: 20px;

$footer-hg: 78px;

$navbar-hg: $navbar-height;

$boxed-max-width: 1140px;
$z-index-main-section: 111;

html {
  /* $replace rtl */
  direction: ltr;
  height: 100%; // http://updates.html5rocks.com/2013/12/300ms-tap-delay-gone-away
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-size: 16px; // force same font size acrros all device
}

html,
body {
  // overflow-x: hidden;
  height: 100%;
  background-color: $content-bg;
}

// Main wrapper
// -----------------------------
.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden;
  &.ng-leave {
    display: none;
  }
  // Contains the main sidebar
  .aside-container {
    // visibility: hidden;
    position: absolute;
    width: $aside-wd;

    top: 0;
    left: 0;
    bottom: 0;
    z-index: $z-index-main-section + 5; // @include transition(visibility 0s linear .3s);
    backface-visibility: hidden;
    background-color: $aside-bg;

    .aside-inner {
      padding-top: 110px;
      height: 100%;
      width: $aside-wd;
      overflow: hidden;
    }

    .nav-floating {
      // position: handled from directive
      left: inherit;
      margin-left: $aside-wd;
      z-index: $z-index-main-section * 10;

      min-width: 190px;
      overflow: auto;
    }
  }

  ul.menu-top {
    img {
      display: none;
    }
  }

  // Contains the main content
  .section-container {
    position: relative;
    height: 100%;
    margin-left: 0; // overflow: hidden;
    z-index: $z-index-main-section;
    background-color: $content-bg;
    // margin-bottom: $footer-hg !important; // ensure a space for the footer
  } // Page Footer
  .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $footer-hg;
    border-top: 1px solid $gray-light;
    padding: $content-padding;
    z-index: $z-index-main-section - 2;
    font-size: 0.875rem;
    p {
      margin: 0;
    }
  }
}

.modal-open .wrapper .section-container {
  z-index: inherit;
  transform: none !important;
  -webkit-transform: none !important;
}

// Page main content
// -----------------------------
.content-wrapper {
  padding: 15px;
  width: 100%; // styling to visually wrap the component
  max-width: 1402px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;

  .unwrap {
    margin: -15px; // expand over wrapper padding
    @include media-breakpoint-up(md) {
      margin: -20px; // expand over wrapper padding
    }
  }

  .container,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(md) {
    padding: $content-padding; //overflow: auto; // required for portlets
  }
}

// Desktop layout
// -----------------------------
@include media-breakpoint-up(md) {
  body {
    min-height: 100%;
  }

  .wrapper {
    .aside-container {
      .aside-inner {
        // padding-top: $navbar-hg;
      }
    }

    // .section-container,
    // .footer-container {
    //     margin-left: $aside-wd;
    // }

    .section-container {
      // with just a margin we can make the
      // right sidebar always visible
      &.has-sidebar-right {
        margin-right: $aside-wd + 20;
        + .offsidebar {
          z-index: 1;
        }
      }
    }
  }
}

// Aside toggled layout
// On mobile acts like offcanvas
// -----------------------------
@include media-breakpoint-down(sm) {
  .wrapper {
    .aside-container {
      margin-left: -$aside-wd;
    }

    .section-container {
      margin-bottom: 95px;
    }
  }
  .aside-toggled {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd;
      }

      .aside-container {
        margin-left: 0;
      }
    }
  }

  // .header-logo {
  //     width: 130px !important;
  // }

  ul.menu-top {
    background-color: rgb(21 17 31 / 95%);
    position: fixed;
    bottom: 0;
    display: flex !important;
    width: 100%;
    justify-content: center;

    li.nav-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      img {
        display: initial;
      }

      h6 {
        font-size: 10px !important;
      }
    }
  }

  .csstransforms3d {
    .wrapper {
      backface-visibility: hidden;
      .section-container,
      .footer-container {
        margin-left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease;
      }
      .aside-container {
        margin-left: 0;
        transform: translate3d(-$aside-wd, 0, 0);
        transition: transform 0.3s ease;
      }
    }

    .aside-toggled {
      .wrapper {
        .section-container,
        .footer-container {
          transform: translate3d($aside-wd, 0, 0);
        }
        .aside-container {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  .welcome-bottom-footer {
    span {
      display: none;
    }
  }
}

.h-responsive {
  height: 100%;
}

// Toggle and collapsed behavior overrides
@include media-breakpoint-down(sm) {
  .aside-collapsed .wrapper {
    .aside-container {
      margin-left: -$aside-wd-collapsed;
    }
  }

  .aside-collapsed.aside-toggled {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed;
      }

      .aside-container {
        margin-left: 0;
      }
    }
  }

  .h-responsive {
    height: 100vh;
  }

  .csstransforms3d {
    .aside-collapsed .wrapper {
      backface-visibility: hidden;
      .section-container,
      .footer-container {
        margin-left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease;
      }
      .aside-container {
        margin-left: 0;
        transform: translate3d(-$aside-wd-collapsed, 0, 0);
        transition: transform 0.3s ease;
      }
    }

    .aside-collapsed.aside-toggled {
      .wrapper {
        .section-container,
        .footer-container {
          transform: translate3d($aside-wd-collapsed, 0, 0);
        }
        .aside-container {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

// Aside collapsed layout
// -------------------------------
// Aside status toggled via JS
.aside-collapsed {
  overflow-y: auto;
  .wrapper {
    .aside-container {
      &,
      .aside-inner {
        width: $aside-wd-collapsed;
      }
      .nav-floating {
        margin-left: $aside-wd-collapsed;
      }
    }
  }
}

// Margin only exists above tablet
@include media-breakpoint-up(md) {
  // Aside status toggled via JS
  .aside-collapsed {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed;
      }
    }
  }
}

// ------------------------------
// Collapsed variation with text
// ------------------------------
@include media-breakpoint-down(sm) {
  .aside-collapsed-text .wrapper {
    .aside-container {
      margin-left: -$aside-wd-collapsed-text;
    }
  }

  .aside-collapsed-text.aside-toggled {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed-text;
      }

      .aside-container {
        margin-left: 0;
      }
    }
  }

  .img-dashboard {
    height: 52px !important;
  }

  .bg-register {
    background-position: bottom bottom !important;
    background-size: auto 100px !important;
    bottom: 83px !important;
  }

  .bg-energy-cloud {
    bottom: 50px !important;
    left: -140px !important;
  }

  .csstransforms3d {
    .aside-collapsed-text .wrapper {
      backface-visibility: hidden;
      .section-container,
      .footer-container {
        margin-left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease;
      }
      .aside-container {
        margin-left: 0;
        transform: translate3d(-$aside-wd-collapsed-text, 0, 0);
        transition: transform 0.3s ease;
      }
    }

    .aside-collapsed-text.aside-toggled {
      .wrapper {
        .section-container,
        .footer-container {
          transform: translate3d($aside-wd-collapsed-text, 0, 0);
        }
        .aside-container {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

// Aside collapsed layout
// -------------------------------
// Aside status toggled via JS
.aside-collapsed-text {
  overflow-y: auto;
  .wrapper {
    .aside-container {
      &,
      .aside-inner {
        width: $aside-wd-collapsed-text;
      }
      .nav-floating {
        margin-left: $aside-wd-collapsed-text;
      }
    }
  }
}

// Margin only exists above tablet
@include media-breakpoint-up(md) {
  // Aside status toggled via JS
  .aside-collapsed-text {
    .wrapper {
      .section-container,
      .footer-container {
        margin-left: $aside-wd-collapsed-text;
      }
    }
  }
}

// end collapsed variation with text
// mixin to remove transformations
@mixin disable-transform() {
  -webkit-transform: none;
  -moz-transform: none;
  -opera-transform: none;
  -ms-transform: none;
  transform: none;
}

// Fixed layout
// -----------------------------
.layout-fixed {
  @media (max-width: $boxed-max-width) {
    &.aside-toggled,
    &.offsidebar-open {
      overflow-y: hidden;
    }
  }

  .wrapper {
    .topnavbar-wrapper {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $z-index-main-section + 10;
    }
    .aside-container,
    .offsidebar {
      position: fixed;
    }
    /* only applied to sidebar */
    .aside-container {
      /* safari fix */
      height: 1px;
      min-height: 100%;
      /* ios blanks space fix */
      .aside-inner {
        position: fixed;
        top: 0;
        bottom: 0;
      }
    }
    .section-container {
      margin-top: $navbar-hg;

      @include media-breakpoint-up(lg) {
        margin-top: 80px;
      }
    }
  }
}

// Boxed layout (only for desktops)
// -----------------------------
.layout-boxed {
  @include media-breakpoint-up(lg) {
    overflow: auto !important;
    .wrapper {
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);

      .offsidebar {
        position: absolute !important;
      }

      .aside-container {
        left: inherit;
      }
      &,
      & .topnavbar-wrapper {
        width: 970px;
      }
    }

    &.layout-fixed {
      .wrapper .aside-container .aside-inner {
        left: inherit;
      }
    }
  } // max size for large devices
  @include media-breakpoint-up(xl) {
    .wrapper {
      &,
      & .topnavbar-wrapper {
        width: $boxed-max-width;
      }
    }
  }
} // boxed

// Sidebar backdrop
.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: $aside-wd;
  z-index: $z-index-main-section + 1;
  .aside-collapsed & {
    left: $aside-wd-collapsed;
  }
  .aside-collapsed-text & {
    left: $aside-wd-collapsed-text;
  }
}

// Register Background

.position-brand {
  position: fixed;
  top: 0;
  z-index: 3;

  // img {
  //     height: 44px;
  //     width: auto;
  // }

  // .welcome-header-logo {
  //     height: 44px;
  //     width: auto;
  // }
}

@media (max-width: 991px) {
  .position-brand {
    position: fixed;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin: 20px 0 0 0;

    .register-logo {
      height: 15% !important;
    }
  }

  ul.menu-top {
    background-color: rgb(21 17 31 / 95%);
    position: fixed;
    bottom: 0;
    display: flex !important;
    width: 100%;
    justify-content: center;

    li.nav-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      img {
        display: initial;
      }

      h6 {
        font-size: 10px !important;
      }
    }
  }
}

@media (max-width: 700px) {
  .position-brand {
    position: fixed;
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin: 10px 0;

    .register-logo {
      height: 15% !important;
    }

    img {
      height: 46px;
      width: auto;
    }
  }
}

.bg-register {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  width: 100%;
  position: relative;
  // bottom: 65px;
}

.bg-energy-cloud {
  background-image: url('/assets/img/bg-energy-cloud.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  top: 0;
  animation: animateCloud 10s linear alternate-reverse infinite;
}

@keyframes animateCloud {
  100% {
    transform: translateX(95px);
  }
}

@-webkit-keyframes animateCloud {
  100% {
    -webkit-transform: translateX(95px);
  }
}

.bg-energy-turbine-1 {
  background-image: url('/assets/img/bg-energy-turbine-1.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  top: 0;
  animation: animateTurbine1 50s linear infinite;
}

@keyframes animateTurbine1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes animateTurbine1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.img-dashboard {
  width: auto;
  height: 70px;
}

.welcome-footer {
  background-color: transparent;
  position: fixed;
  bottom: 0;
  height: 56px;
  width: 100%;
  bottom: 33px;
  z-index: 2;
  margin: 0 24px;
}

.welcome-bottom-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 34px;
  font-size: 12px;
  padding: 7px 24px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  padding: 5px;
}

::-moz-scrollbar {
  width: 5px;
  padding: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}

::-moz-scrollbar-track {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}
